import React, { memo } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';

import './QuoteCarouselItem.scss';

import GatsbyImage from 'gatsby-image';
import { customPropTypes } from '../../../util';

function QuoteCarouselItem({ data: { description, author, logoBackgroundColor, image } }) {
  return (
    <div className="QuoteCarouselItem">
      <div className="content">
        <h5 className="quote">{description}</h5>
        <p className="quote-author">{author}</p>
      </div>

      <div className="logo-container" style={{ backgroundColor: logoBackgroundColor }}>
        <GatsbyImage className="logo" fluid={image?.localFile.childImageSharp.fluid} alt={image?.altText} />
      </div>
    </div>
  );
}

QuoteCarouselItem.propTypes = checkExtraProps({
  data: PropTypes.shape({
    description: PropTypes.string,
    author: PropTypes.string,
    logoBackgroundColor: PropTypes.string,
    image: customPropTypes.image,
  }).isRequired,
});

QuoteCarouselItem.defaultProps = {};

export default memo(QuoteCarouselItem);
